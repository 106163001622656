<div (window:resize)="onResize($event)" class="component homepage">
   <div class="blue">
      <div class="d-flex justify-content-between container-fluid">
         <div class="justify-content-between d-flex btn-div-left">
            <button
               [value]="1"
               [ngClass]="{ active: current_state === '1' }"
               (click)="setVista1()"
               class="btn-secondary w-100 mx-2 border-0 rounded d-md-block d-none"
            >
               Vista Singola
            </button>
            <ng-select
               class="w-100"
               [items]="model.layers"
               bindLabel="name"
               [(ngModel)]="selected_layer"
               (change)="onChangeMapLayer($event)"
               [disabled]="current_state!='1'"
            ></ng-select>
         </div>
         <div class="btn-div-right justify-content-between d-md-flex d-none">
            <button
               [value]="6"
               [ngClass]="{ active: current_state === '2' }"
               (click)="setVista2()"
               class="btn-secondary border-0 rounded"
            >
               Tutte le viste
            </button>
            <button
               [ngClass]="{ active: current_state === '3' }"
               (click)="setVista3()"
               class="btn-secondary mx-2 border-0 rounded"
            >
               Vista crolli distacco e propagazione
            </button>
            <button
               [value]="2"
               [ngClass]="{ active: current_state === '4' }"
               (click)="setVista4()"
               class="btn-secondary border-0 rounded"
            >
               Viste Incendi
            </button>
         </div>
      </div>
   </div>

   <div class="mapbox-container" *ngIf="isPlatformBrowser && view">
      <div *ngFor="let n of Array(maps_num).fill(1); let i = index" class="mapbox mapbox-{{ maps_num }}">
         <div ngbDropdown class="info-box">
            <i class="bi bi-info-circle" id="dropdownBasic1" ngbDropdownToggle></i>
            <div class="panel-legend" ngbDropdownMenu aria-labelledby="dropdownBasic1">
               <div class="p-3 detail-info-popup">
                  <h5>{{layers[i][1].name}}</h5>
                  <p>{{layers[i][1].description}}</p>
                  <img
                     *ngIf="layers[i][1].options.source.params.LAYERS"
                     class="popup-img" 
                     src="/geoserver/gestisco/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&LAYER={{layers[i][1].options.source.params.LAYERS}}&format=image/png"
                     alt="" 
                  />
               </div>
            </div>
          </div>
         <ng-map *ngIf="render" [target]="'map' + i" [view]="view" [layers]="layers[i]" (onMap)="onMap($event)"></ng-map>
         <ng-map-control-attribution
            *ngIf="maps['map' + i]"
            [map]="maps['map' + i]"
            [options]="{ collapsible: false }"
         ></ng-map-control-attribution>
      </div>
   </div>

   <div class="icon-div d-flex flex-column justify-content-between" *ngIf="isPlatformBrowser && Object.keys(maps).length">
      <ng-map-control-zoom [maps]="maps"></ng-map-control-zoom>
      <ng-map-control-baselayer [maps]="maps" [base_layers]="base_layers"></ng-map-control-baselayer>
      <ng-map-control-geolocation [maps]="maps"></ng-map-control-geolocation>
   </div>
</div>
