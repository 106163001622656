import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactsComponent } from './components/contacts/contacts.component';
import { PageViewComponent } from './components/page-view/page-view.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { NewsViewComponent } from './components/news-view/news-view.component';
import { ErrorComponent } from './components/error/error.component';
import { SwiperModule } from 'swiper/angular';
import { FormsModule } from '@angular/forms';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { SusceptibilityComponent } from './components/susceptibility/susceptibility.component';
import { InstructionManualComponent } from './components/instruction-manual/instruction-manual.component';
import { PublicationsComponent } from './components/publications/publications.component';
import { LinksComponent } from './components/links/links.component';
import { GestiscoProjectComponent } from './components/gestisco-project/gestisco-project.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { NgMapComponent } from './components/ng-map/ng-map.component';
import { HomeMenuComponent } from './components/homepage/home-menu/home-menu.component';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { ControlGeolocationComponent } from './components/ng-map/control-geolocation/control-geolocation.component';
import { ControlZoomComponent } from './components/ng-map/control-zoom/control-zoom.component';
import { ControlAttributionComponent } from './components/ng-map/control-attibution/control-attibution.component';
import { ControlBaselayerComponent } from './components/ng-map/control-baselayer/control-baselayer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { NgCommonStrapiModule } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';

/* Best practices for Error handling in Angular
 * https://medium.com/angular-in-depth/expecting-the-unexpected-best-practices-for-error-handling-in-angular-21c3662ef9e4
 */

@Injectable({ providedIn: 'root' })
export class CustomErrorHandler implements ErrorHandler {
   constructor() {
      // do nothing.
   }

   handleError(error: any) {
      /* Angular custom error handler not getting error type from promise
       * https://stackoverflow.com/a/54826258
       */
      if (error.promise && error.rejection) {
         // Promise rejection wrapped by zone.js
         error = error.rejection;
      }
      if (error.error) {
         error = error.error;
      }

      let message = null;
      const stackTrace = null;

      if (error instanceof HttpErrorResponse) {
         // Server Error
         message = error.message;
      } else {
         // Client Error
         message = error.message || error.toString();
      }

      //   this.snackbarService.error(message);

      // Always log errors
      // console.error(message, stackTrace);
      console.error(error);
   }
}

@NgModule({
   declarations: [
      AppComponent,
      NavComponent,
      FooterComponent,
      HomepageComponent,
      ContactsComponent,
      PageViewComponent,
      NewsListComponent,
      NewsViewComponent,
      ErrorComponent,
      SusceptibilityComponent,
      InstructionManualComponent,
      PublicationsComponent,
      LinksComponent,
      GestiscoProjectComponent,
      NgMapComponent,
      HomeMenuComponent,
      NewsCardComponent,
      ControlGeolocationComponent,
      ControlZoomComponent,
      ControlAttributionComponent,
      ControlBaselayerComponent,
   ],
   imports: [
      NgxJsonLdModule,
      BrowserModule.withServerTransition({ appId: 'serverApp' }),
      AppRoutingModule,
      NgbModule,
      HttpClientModule,
      SwiperModule,
      NgSelectModule,
      FormsModule,
      TransferHttpCacheModule,
      NgCommonStrapiModule.forRoot({
         strapi: {
            url: environment.cms,
         },
      }),
      MarkdownModule.forRoot({
         loader: HttpClient, // optional, only if you use [src] attribute
         markedOptions: {
            provide: MarkedOptions,
            useValue: {
               gfm: true,
               breaks: false,
               pedantic: false,
               smartLists: true,
               smartypants: false,
            },
         },
      }),
   ],
   providers: [
      {
         provide: ErrorHandler,
         useClass: CustomErrorHandler,
      },
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
