<!-- MOBILE -->
<div class="{{ clicked ? 'open' : '' }} slider d-block d-lg-none">
   <div (click)="toggle()">
      <span class="icon-bar d-block d-lg-none"> </span>

      <div class="p-3 mt-4 value-div">
         <h3>Italia</h3>
         <div class="d-flex justify-content-between">
            <!-- <p><span>Interventi: </span>6738</p>
            <p><span>Importi: </span>1.234.567.000,00€</p> -->
         </div>
      </div>
   </div>
   <div class="p-3 w-100">
      <div class="w-100">
         <ng-select [items]="model.layers" bindLabel="name" [(ngModel)]="selected_layer"></ng-select>
      </div>
   </div>
   <hr />
   <div class="bottom-div">
      <div>
         <div class="p-3 pb-5 mt-4">
            <h4 class="mb-4 pb-4">Dettagli</h4>
            <!-- <div class="d-block justify-content-between">
               <div class="detail-div py-3 my-2">
                  <p>1.234.000,00€</p>
                  <h5>Assegnati</h5>
               </div>
               <hr />
               <div class="detail-div py-3 my-2">
                  <p>1.234.000,00€</p>
                  <h5>Approvati</h5>
               </div>
               <hr />
               <div class="detail-div py-3 my-2">
                  <p>1.234.000,00€</p>
                  <h5>Attivati</h5>
               </div>
               <hr />
               <div class="detail-div py-3 my-2">
                  <p>1.234.000,00€</p>
                  <h5>Aggiudicati</h5>
               </div>
               <hr />
               <div class="detail-div py-3 my-2">
                  <p>1.234.000,00€</p>
                  <h5>Erogati</h5>
               </div>
               <hr />
               <div class="detail-div pt-3">
                  <p>1.234.000,00€</p>
                  <h5>Conclusi</h5>
               </div>
            </div> -->
         </div>
      </div>
   </div>
</div>

<!-- DESKTOP -->
<div class="slider d-none d-lg-block">
   <!-- <div class="p-3 mt-4">
      <button *ngFor="let n of maps_values" [value]="n" [ngClass]="{ active: n == maps_num }" (click)="changeMapNum(n)">{{ n }}</button>
   </div> -->

   <div class="w-100 p-3 btn-group btn-group-toggle" ngbRadioGroup name="radioBasic">
      <label *ngFor="let n of maps_values" [ngClass]="{ active: n == maps_num }" (click)="changeMapNum(n)"
         ngbButtonLabel class="btn-primary btn active">
         <input style="display: none" ngbButton type="radio" [value]="n" /> {{ n }}
      </label>
   </div>

   <div class="p-3">
      <h3>Italia</h3>
      <div class="d-flex justify-content-between">
         <!-- <p><span>Interventi: </span>6738</p>
         <p><span>Importi: </span>1.234.567.000,00€</p> -->
      </div>
   </div>
   <div class="p-3 w-100">
      <div class="w-100">
         <ng-select [items]="model.layers" bindLabel="name" [(ngModel)]="selected_layer" (change)="onLayerChange($event)"></ng-select>
      </div>
   </div>
   <hr />
   <div class="bottom-div">
      <div>
         <div class="p-3 pb-5 mt-4">
            <h4 class="mb-4 pb-4">Dettagli</h4>
            <!-- <div class="d-block justify-content-between">
               <div class="detail-div pt-3">
                  <p>1.234.000,00€</p>
                  <h5>Assegnati</h5>
               </div>
               <hr />
               <div class="detail-div pt-3">
                  <p>1.234.000,00€</p>
                  <h5>Approvati</h5>
               </div>
               <hr />
               <div class="detail-div pt-3">
                  <p>1.234.000,00€</p>
                  <h5>Attivati</h5>
               </div>
               <hr />
               <div class="detail-div pt-3">
                  <p>1.234.000,00€</p>
                  <h5>Aggiudicati</h5>
               </div>
               <hr />
               <div class="detail-div pt-3">
                  <p>1.234.000,00€</p>
                  <h5>Erogati</h5>
               </div>
               <hr />
               <div class="detail-div pt-3">
                  <p>1.234.000,00€</p>
                  <h5>Conclusi</h5>
               </div>
            </div> -->
         </div>
      </div>
   </div>
</div>