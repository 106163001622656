<div class="component news-list">
   <div class="container-fluid content pt-3 mt-5">
      <h2>News</h2>
      <div class="row">
         <app-news-card class="col-md-4" *ngFor="let info of news" [news]="info"></app-news-card>
      </div>
      <div class="d-flex justify-content-center pb-5 mb-3">
         <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshNews()">
         </ngb-pagination>
      </div>
   </div>
</div>
