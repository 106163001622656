<div class="news-div">
   <h4 class="date py-3">{{ luxon.DateTime.fromISO(news.created_at).setLocale(news.locale).toFormat('d MMMM yyyy') }}</h4>

   <div [routerLink]="['/news', news.slug]" class="img-fluid" style="background-image: url('{{ getCoverUrl() }}');"></div>

   <h3 [routerLink]="['/news', news.slug]" class="pt-3 pb-2">{{ news.meta.title }}</h3>
   <!-- <p class="card-text preview mb-3 pb-3">
      {{ news.meta.description || news.text }}
   </p> -->
   <markdown class="card-text preview mb-3 pb-3" lineNumbers [start]="5">
      {{ news.meta.description || news.text }}
   </markdown>
   <a class="mt-3" [routerLink]="['/news', news.slug]">— &nbsp; {{ 'SCOPRI_DI_PIU' | translate }}</a>
</div>
