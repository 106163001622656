<div class="component footer">
   <div class="border-div">
      <div class="container-fluid footer-div">
         <div class="row align-items-center">
            <div class="col-lg-8 offset-lg-2">
               <div class="row justify-content-center">
                  <div class="my-5 align-self-center col-6 col-md-3" *ngFor="let x of model.footer_logos">
                     <img class="img-fluid" src="{{ getCoverUrl(x.logo.url) }}" alt="" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="container-fluid">
      <div class="link_div align-items-center d-flex justify-content-between m-auto row text-center">
         <a (click)="scroll()" [routerLink]="['/page', x.link]" class="m-0 col-12 col-lg" *ngFor="let x of model.footer_links">{{
            x.title
         }}</a>
      </div>
   </div>
</div>
