import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
   selector: 'app-home-menu',
   templateUrl: './home-menu.component.html',
   styleUrls: ['./home-menu.component.scss'],
})
export class HomeMenuComponent implements OnInit {
   clicked = false;
   @Input() model!: any;
   @Input() maps_values: number[] = [1];
   @Input() default = 1;
   @Output() onChangeMapNum = new EventEmitter<number>();
   @Output() onChangeMapLayer = new EventEmitter<any>();

   maps_num!: number;
   selected_layer!: any;

   constructor() {
      this.maps_num = this.default;
   }

   ngOnInit(): void {
      this.selected_layer = this.model.layers[0];
   }

   toggle(): void {
      this.clicked = !this.clicked;
   }

   changeMapNum(maps_num: number) {
      this.maps_num = maps_num;
      this.onChangeMapNum.emit(maps_num);
   }

   onLayerChange($event: any) {
      this.onChangeMapLayer.emit($event);
   }
}
