<div class="component navbar-component sticky-top">
   <nav *ngIf="settings" class="navbar navbar-expand-lg">
      <div class="container-fluid">
         <a class="navbar-brand" routerLink="/">
            <img class="img-fluid" src="{{ getCoverUrl() }}" alt="" />
         </a>

         <span
            type="button"
            (click)="collapse.toggle()"
            [attr.aria-expanded]="!gfg"
            aria-controls="collapseExample"
            class="{{ gfg ? 'not-collapsed' : 'collapsed' }} d-block d-lg-none"
         >
            <span class="icon-bar"></span>
         </span>

         <!------------------------------ DESKTOP ------------------------------>
         <div class="d-none d-lg-flex collapse navbar-collapse">
            <ul *ngIf="model" class="navbar-nav me-auto mb-2 mb-lg-0">
               <li (click)="collapse.toggle()" class="nav-item px-2" *ngFor="let item of model.items">
                  <a class="nav-link active" *ngIf="item.type == 'SINGLE_TYPE'" routerLink="/{{ item.location }}">{{ item.title }}</a>
                  <a class="nav-link active" *ngIf="item.type == 'PAGE'" [routerLink]="['/page', item.location]">{{ item.title }}</a>
                  <a class="nav-link active" *ngIf="item.type == 'NEWS'" [routerLink]="['/news', item.location, item.id]">{{
                     item.title
                  }}</a>
                  <a class="nav-link active" *ngIf="item.type == 'URL'" target="_blank" routerLink="{{ item.location }}">{{
                     item.title
                  }}</a>
               </li>

               <li *ngIf="langs.length > 1" class="nav-item">
                  <div ngbDropdown class="d-inline-block drop nav-link">
                     <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{ translate.currentLang }}</button>
                     <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem *ngFor="let lang of translate.getLangs()" ngbDropdownItem (click)="useLanguage(lang)">
                           {{ lang }}
                        </button>
                     </div>
                  </div>
               </li>
            </ul>
         </div>
         <!-------------------------------------------------------------------->

         <!------------------------------ MOBILE  ------------------------------>

         <div #collapse="ngbCollapse" [(ngbCollapse)]="gfg" class="overlay">
            <div class="d-block d-lg-none" style="height: 100vh">
               <ul *ngIf="model" class="navbar-nav me-auto mb-2 mb-lg-0 overlay-content">
                  <li class="nav-item px-2" *ngFor="let item of model.items">
                     <a
                        (click)="collapse.toggle()"
                        class="nav-link active"
                        *ngIf="item.type == 'SINGLE_TYPE'"
                        routerLink="/{{ item.location }}"
                        >{{ item.title }}</a
                     >

                     <a
                        (click)="collapse.toggle()"
                        class="nav-link active"
                        *ngIf="item.type == 'PAGE'"
                        [routerLink]="['/page', item.location]"
                        >{{ item.title }}</a
                     >

                     <a
                        (click)="collapse.toggle()"
                        class="nav-link active"
                        *ngIf="item.type == 'NEWS'"
                        [routerLink]="['/news', item.location, item.id]"
                        >{{ item.title }}</a
                     >

                     <a
                        (click)="collapse.toggle()"
                        class="nav-link active"
                        *ngIf="item.type == 'URL'"
                        target="_blank"
                        routerLink="{{ item.location }}"
                        >{{ item.title }}</a
                     >
                  </li>

                  <li (click)="collapse.toggle()" class="nav-item px-2">
                     <a class="nav-link active" routerLink="/">Mappe</a>
                  </li>
               </ul>
               <div class="blue" style="position: relative"></div>
            </div>
         </div>
         <!-------------------------------------------------------------------->
      </div>
   </nav>
   <div class="blue blue-nav"></div>
</div>
