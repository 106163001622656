import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StrapiService, SingleTypeComponent, MetaService } from '@sciamlab/ng-common-strapi';

@Component({
   selector: 'app-instruction-manual',
   templateUrl: './instruction-manual.component.html',
   styleUrls: ['./instruction-manual.component.scss'],
})
export class InstructionManualComponent extends SingleTypeComponent implements OnInit {
   constructor(route: ActivatedRoute, router: Router, strapi: StrapiService, translate: TranslateService, meta: MetaService) {
      super(route, router, strapi, translate, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
   }
}
