import { Component, OnInit, ChangeDetectionStrategy, Input, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Map, View } from 'ol';
import { Layer } from 'ol/layer';
import { Source } from 'ol/source';
import { LayerFactory, NgMapService } from './ng-map.service';

@Component({
  selector: 'ng-map',
  template: '<div [id]="target" class="ng-map"></div>',
  styleUrls: ['./ng-map.component.scss'],
})
export class NgMapComponent implements OnInit, AfterViewInit, OnDestroy {
  map!: Map;
  @Input() target = "ng-map";
  @Input() center = [0, 0];
  @Input() zoom = 1;
  @Input() layers: Layer<Source>[] = [];
  @Input() controls = [];
  @Input() view!: View;
  @Output() onMap = new EventEmitter<Map>();
  @Output() onView = new EventEmitter<View>();

  constructor(protected mapService: NgMapService) { }

  ngOnInit(): void {
    // empty
  }

  ngAfterViewInit(): void {
    console.log('creating', this.target);
    this.map = this.mapService.create(this.target, { 
      view: this.view, 
      center: this.center, 
      zoom: this.zoom, 
      layers: this.layers.map((l: any) => LayerFactory.build(l.type, l.options)), 
      controls: this.controls 
    });
    this.onMap.emit(this.map)
    this.onView.emit(this.map.getView())
  }

  ngOnDestroy(): void {
    console.log('destroying', this.map.getTarget());
  }
}
