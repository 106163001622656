<div class="publications comp">
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-8 col-12">
            <h2 class="pb-2 pt-5">{{ model.meta.title }}</h2>
            <div class="py-4" *ngFor="let detail of model.publications">
               <markdown class="publications_md" lineNumbers [start]="5">{{ detail.abstract }}</markdown>

               <div class="py-3" *ngFor="let x of detail.docs">
                  <!-- <p>{{ luxon.DateTime.fromISO(x.created_at).setLocale('it').toFormat('d MMMM yyyy') }}</p> -->
                  <p>{{ detail.date }}</p>
                  <a target="_blank" href="{{ (x.url.startsWith('/') ? environment.cms : '') + x.url }}">Download</a>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
