import { isPlatformBrowser, Location } from '@angular/common';
import { Component, HostBinding, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment.prod';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
   route!: string;
   settings: any;
   environment = environment;
   home = false;
   @HostBinding('class') public cssClass = '';

   constructor(private location: Location, private strapi: StrapiService, @Inject(PLATFORM_ID) private platformId: any) {}

   async ngOnInit(): Promise<any> {
      this.route = this.location.path();

      if (this.route.charAt(0) === '?' || this.route === '') {
         this.home = true;
      }

      this.settings = this.strapi.getSettings();

      if (isPlatformBrowser(this.platformId)) {
         const favicon: any = document.querySelector('#favicon');

         favicon.href = this.strapi.getImageUrl(this.settings.favicon.url);
      }
   }
}
