<div class="container-wrapper">
   <section class="container_inner">
      <app-nav></app-nav>
      <div class="content-div">
         <router-outlet></router-outlet>
      </div>
   </section>
   <section>
      <app-footer></app-footer>
   </section>
</div>
