import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
   selector: 'app-error',
   templateUrl: './error.component.html',
   styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
   error_code: number;

   constructor(private route: ActivatedRoute) {
      this.error_code = this.route.snapshot.params.code || 404;
   }

   ngOnInit(): void {
      // noop
   }
}
