import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StrapiService, SingleTypeComponent, MetaService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';
import * as luxon from 'luxon';

@Component({
   selector: 'app-publications',
   templateUrl: './publications.component.html',
   styleUrls: ['./publications.component.scss'],
})
export class PublicationsComponent extends SingleTypeComponent implements OnInit {
   environment = environment;
   luxon = luxon;

   constructor(route: ActivatedRoute, router: Router, strapi: StrapiService, translate: TranslateService, meta: MetaService) {
      super(route, router, strapi, translate, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
   }
}
