import { Component, OnInit, Input } from '@angular/core';
import Map from 'ol/Map';
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import { Layer, Vector as VectorLayer } from 'ol/layer';
import { Source, Vector as VectorSource } from 'ol/source';
import Point from 'ol/geom/Point';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import Geometry from 'ol/geom/Geometry';
import { LayerFactory, NgMapService } from '../ng-map.service';

@Component({
  selector: 'ng-map-control-baselayer',
  templateUrl: './control-baselayer.component.html',
  styleUrls: ['./control-baselayer.component.scss'],
})
export class ControlBaselayerComponent implements OnInit {
  @Input() maps: { [key: string]: Map } = {};
  @Input() base_layers: any[] = [];
  active = false;

  constructor(protected mapService: NgMapService) { }

  ngOnInit() {
    // noop
  }

  toggleBaselayer() {
    this.active = !this.active;
    if (this.base_layers.length) {
      const old = this.base_layers.shift() as Layer<Source>;
      this.base_layers.push(old);
      Object.values(this.maps).forEach(map => {
        this.mapService.setLayer(map, 0, this.base_layers[0]);
      });
    }
  }
}
