import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StrapiService, SingleTypeComponent, MetaService } from '@sciamlab/ng-common-strapi';

@Component({
   selector: 'app-contacts',
   templateUrl: './contacts.component.html',
   styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent extends SingleTypeComponent implements OnInit {
   public message!: string;
   loading = true;
   settings: any;
   lat!: number;
   lon!: number;

   constructor(route: ActivatedRoute, router: Router, strapi: StrapiService, translate: TranslateService, meta: MetaService) {
      super(route, router, strapi, translate, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();

      this.webpage = {
         ...this.webpage,
         '@type': 'ContactPage',
      };
   }
}
