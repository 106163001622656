import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { NewsViewComponent } from './components/news-view/news-view.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { PageViewComponent } from './components/page-view/page-view.component';
import { ErrorComponent } from './components/error/error.component';
import { PublicationsComponent } from './components/publications/publications.component';
import { CollectionTypeResolver, StrapiService } from '@sciamlab/ng-common-strapi';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SingleTypeResolver implements Resolve<any> {
   constructor(
      private strapi: StrapiService, 
      private translate: TranslateService
      ) {}

   async resolve(route: ActivatedRouteSnapshot): Promise<any> {
      try {
         return await this.strapi.getSingleType(route.data.id, this.translate.currentLang);
      } catch (error) {
         console.error(error);
         return null;
      }
   }
}
const routes: Routes = [
   {
      path: '',
      component: HomepageComponent,
      runGuardsAndResolvers: 'always',
      data: {
         id: 'home',
      },
      resolve: {
         model: SingleTypeResolver,
      },
   },
   // {
   //    path: 'susceptibility',
   //    component: SusceptibilityComponent,
   //    runGuardsAndResolvers: 'always',
   //    data: {
   //       id: 'susceptibility',
   //    },
   //    resolve: {
   //       model: SingleTypeResolver,
   //    },
   // },
   // {
   //    path: 'instruction-manual',
   //    component: InstructionManualComponent,
   //    runGuardsAndResolvers: 'always',
   //    data: {
   //       id: 'instruction-manual',
   //    },
   //    resolve: {
   //       model: SingleTypeResolver,
   //    },
   // },
   {
      path: 'publications',
      component: PublicationsComponent,
      runGuardsAndResolvers: 'always',
      data: {
         id: 'publications',
      },
      resolve: {
         model: SingleTypeResolver,
      },
   },
   // {
   //    path: 'links',
   //    component: LinksComponent,
   //    runGuardsAndResolvers: 'always',
   //    data: {
   //       id: 'links',
   //    },
   //    resolve: {
   //       model: SingleTypeResolver,
   //    },
   // },
   // {
   //    path: 'gestisco-project',
   //    component: GestiscoProjectComponent,
   //    runGuardsAndResolvers: 'always',
   //    data: {
   //       id: 'gestisco-project',
   //    },
   //    resolve: {
   //       model: SingleTypeResolver,
   //    },
   // },
   {
      path: 'page',
      children: [
         {
            path: ':id',
            component: PageViewComponent,
            runGuardsAndResolvers: 'always',
            data: {
               id: 'pages',
            },
            resolve: {
               model: CollectionTypeResolver,
            },
         },
      ],
   },
   {
      path: 'news',
      children: [
         {
            path: '',
            component: NewsListComponent,
            runGuardsAndResolvers: 'always',
            data: {
               id: 'news',
            },
            resolve: {
               model: CollectionTypeResolver,
            },
         },
         {
            path: ':id',
            component: NewsViewComponent,
            runGuardsAndResolvers: 'always',
            data: {
               id: 'news',
            },
            resolve: {
               model: CollectionTypeResolver,
            },
         },
      ],
   },
   {
      path: 'error/:code',
      component: ErrorComponent,
   },
   {
      path: '**',
      component: ErrorComponent,
   },
];

@NgModule({
   imports: [
      RouterModule.forRoot(routes, {
         initialNavigation: 'enabled',
         onSameUrlNavigation: 'reload',
      }),
   ],
   exports: [RouterModule],
   providers: [SingleTypeResolver, CollectionTypeResolver],
})
export class AppRoutingModule {}
