<div class="component page-view">
   <div *ngIf="model.cover" class="banner img-fluid" style="background-image:url('{{ getCoverUrl() }}');">
      <div class="cont"></div>
   </div>
   <div *ngIf="model" class="container-fluid justify-content-between d-block d-lg-flex mt-4 pt-4">
      <section>
         <h2>{{ model.meta?.title }}</h2>
         <markdown class="publications_md" lineNumbers [start]="5">{{ model.content }}</markdown>
      </section>

      <section class="download-section">
         <div class="row" *ngIf="model.attachments">
            <div class="download-box m-1 col-12 p-3 d-flex justify-content-between align-items-center" *ngFor="let x of model.attachments">
               <p>{{ x.title }}</p>
               <a target="_blank" href="{{ (x.attachment.url.startsWith('/') ? environment.cms : '') + x.attachment.url }}">
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     fill="currentColor"
                     class="bi bi-file-earmark-arrow-down"
                     viewBox="0 0 16 16"
                  >
                     <path
                        d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"
                     />
                     <path
                        d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
                     />
                  </svg>
               </a>
            </div>
         </div>
      </section>
   </div>
</div>

<!-- if statement coverurl -->
