<div class="component news-view">
   <div class="container-fluid mt-2 pt-3">
      <div class="content">
         <div class="news_view_container row">
            <div class="col-md-12 col-lg-2 pb-5">
               <a routerLink="/news"><i class="bi bi-arrow-left"></i> torna indietro</a>
            </div>
            <div class="col-lg-8 col-md-12 inner-content">
               <h4 class="date pb-2">{{ luxon.DateTime.fromISO(model.created_at).setLocale(model.locale).toFormat('d MMMM yyyy') }}</h4>
               <h1 class="pb-2">{{ model.meta.title }}</h1>
               <div class="row">
                  <img class="col-12 img-fluid" src="{{ getCoverUrl() }}" alt="" />
               </div>
               <div class="pt-5">
                  <markdown>{{ model.content }}</markdown>
               </div>
            </div>
         </div>

         <div class="outer_content py-3">
            <h2 class="py-3">Altre news</h2>
            <div>
               <swiper [config]="config">
                  <ng-template #swiper swiperSlide *ngFor="let detail of news">
                     <div class="news_card py-4">
                        <app-news-card [news]="detail"></app-news-card>
                     </div>
                  </ng-template>
               </swiper>
            </div>
         </div>
      </div>
   </div>
</div>
