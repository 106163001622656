import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Resolve } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';

@Component({
   selector: 'app-footer',
   templateUrl: './footer.component.html',
   styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
   environment = environment;
   model: any;
   settings: any;
   footerInfo: any;
   langChangeSubscription!: Subscription;

   constructor(private activatedRoute: ActivatedRoute, private strapi: StrapiService, public translate: TranslateService) {
      this.model = this.strapi.getSingleType('footer', this.translate.currentLang).then((response) => {
         this.model = response;
      });
   }

   async ngOnInit(): Promise<void> {
      this.langChangeSubscription = this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
         this.model = await this.strapi.getSingleType('footer', this.translate.currentLang);
         this.footerInfo = await this.strapi.getSingleType('footer', this.translate.currentLang);
      });

      this.settings = await this.strapi.getSingleType('settings', null);
      this.footerInfo = await this.strapi.getSingleType('footer', this.translate.currentLang);
   }

   scroll() {
      window.scrollTo(0, 0);
   }

   getCoverUrl(url: string): string {
      return this.strapi.getImageUrl(url);
   }
}

@Injectable()
export class NavResolver implements Resolve<any> {
   constructor(private strapi: StrapiService, private translate: TranslateService) {}

   async resolve(): Promise<any> {
      return await this.strapi.getSingleType('footer', this.translate.currentLang);
   }
}
