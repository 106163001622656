import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StrapiService, SingleTypeComponent, MetaService } from '@sciamlab/ng-common-strapi';

@Component({
   selector: 'app-gestisco-project',
   templateUrl: './gestisco-project.component.html',
   styleUrls: ['./gestisco-project.component.scss'],
})
export class GestiscoProjectComponent extends SingleTypeComponent implements OnInit {
   constructor(route: ActivatedRoute, router: Router, strapi: StrapiService, translate: TranslateService, meta: MetaService) {
      super(route, router, strapi, translate, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
   }
}
