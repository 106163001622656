import { isPlatformBrowser, Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Map, MapEvent, View } from 'ol';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { StrapiService, SingleTypeComponent, MetaService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';
import { LayerFactory, NgMapService } from '../ng-map/ng-map.service';

@Component({
   selector: 'app-homepage',
   templateUrl: './homepage.component.html',
   styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent extends SingleTypeComponent implements OnInit, OnDestroy {
   environment = environment;
   settings: any;
   center = [this.model.lat, this.model.lon];
   zoom = this.model.zoom;
   target = 'map';
   selected_layer!: any;
   base_layers: any[] = [];
   layers: any[][] = [];
   maps: { [key: string]: Map } = {};
   view!: View;
   mapMoveSubscription!: Subscription;
   render = false;
   maps_num = 1;
   Array = Array;
   Object = Object;
   isPlatformBrowser: boolean;
   current_state!: string;
   coords: any;

   constructor(
      route: ActivatedRoute,
      router: Router,
      strapi: StrapiService,
      translate: TranslateService,
      meta: MetaService,
      protected mapService: NgMapService,
      protected location: Location,
      private appComponent: AppComponent,
      @Inject(PLATFORM_ID) private platformId: any,
   ) {
      super(route, router, strapi, translate, meta);
      appComponent.cssClass = 'homepage';
      this.isPlatformBrowser = isPlatformBrowser(this.platformId);
      this.base_layers = this.model.base_layers;
      this.setVista1();
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
      this.view = this.mapService.createView(this.center, this.zoom);
      this.render = true;

      for(const x of this.model.layers) {
         if(parseInt(x.id) === parseInt(this.route.snapshot.queryParams.layer)) {
            this.selected_layer = x
         }
      }
      
      if(this.route.snapshot.queryParams.vista === '1') this.setVista1()
      if(this.route.snapshot.queryParams.vista === '2') this.setVista2()
      if(this.route.snapshot.queryParams.vista === '3') this.setVista3()
      if(this.route.snapshot.queryParams.vista === '4') this.setVista4()
   }

   onResize(event: any) {
      if (this.maps_num === 1) {
         this.current_state = '1';
      } else if (event.target.innerWidth <= 768) {
         this.onChangeMapNum(1);
      }
   }

   onMap(map: Map) {
      const target = map.getTarget() as string;
      if (target === this.target + '0') {
         map.on('moveend', ($event: MapEvent) => {
            console.log($event.map.getTarget());
            this.coords = this.mapService.getCoordinates($event.map);
            console.log('center', this.coords.center, 'zoom', this.coords.zoom);
            // aggiungo o sovrascrivo il parametro @
            if(this.current_state === '1') {
               const params: HttpParams = this.getCurrentParams().set('@', `${this.coords.center.join()},${this.coords.zoom}`).set('vista', this.current_state).set('layer', this.selected_layer.id);
               // aggiorno l'url
               this.location.replaceState(this.location.path().split('?')[0], params.toString());
            } else {
               const params: HttpParams = this.getCurrentParams().set('@', `${this.coords.center.join()},${this.coords.zoom}`).set('vista', this.current_state).delete('layer');
               // aggiorno l'url
               this.location.replaceState(this.location.path().split('?')[0], params.toString());
            }
         });
      }
      this.maps[target] = map;
      console.log('created', target, map);

      //centro la mappa se c'è @
      if (this.route.snapshot.queryParams['@']) {
         const center: number[] = [
            +this.route.snapshot.queryParams['@'].split(',')[1],
            +this.route.snapshot.queryParams['@'].split(',')[0],
         ];
         const zoom: number = +this.route.snapshot.queryParams['@'].split(',')[2];
         this.mapService.goToCenter(map, center, zoom, false);
      }
   }

   setVista1() {
      this.current_state = '1'; 
      this.selected_layer = this.selected_layer || this.model.layers[0]            
      this.layers = [
         [this.base_layers[0], this.selected_layer]
      ];
      this.onChangeMapNum(1)
   }
   setVista2() {
      this.current_state = '2'; 
      this.layers = [
         [this.base_layers[0], this.model.layers[0]],
         [this.base_layers[0], this.model.layers[1]],
         [this.base_layers[0], this.model.layers[2]],
         [this.base_layers[0], this.model.layers[4]],
         [this.base_layers[0], this.model.layers[5]],
         [this.base_layers[0], this.model.layers[3]],
      ];
      this.onChangeMapNum(6)
   }
   setVista3() {
      this.current_state = '3'; 
      this.layers = [
         [this.base_layers[0], this.model.layers[2]],
         [this.base_layers[0], this.model.layers[3]],
      ];
      this.onChangeMapNum(2)
   }
   setVista4() {
      this.current_state = '4'; 
      this.layers = [
         [this.base_layers[0], this.model.layers[4]],
         [this.base_layers[0], this.model.layers[5]],
      ];
      this.onChangeMapNum(2);
   }
   onChangeMapNum($event: number) {
      console.log('onChangeMapNum', $event);
      this.render = false;
      this.maps_num = 0;
      this.maps_num = $event;
      setTimeout(() => {
         this.render = true;
      }, 1000);
   }

   onChangeMapLayer($event: any) {
      console.log('onChangeMapLayer', $event);
      const params: HttpParams = this.getCurrentParams().set('@', `${this.coords.center.join()},${this.coords.zoom}`).set('vista', this.current_state).set('layer', $event.id);

      this.location.replaceState(this.location.path().split('?')[0], params.toString());
      this.mapService.setLayer(Object.values(this.maps)[0], 1, $event);
      this.layers[0][1] = $event;
   }

   getCurrentParams(): HttpParams {
      let params = new HttpParams();
      // riprendo i parametri gia presenti, se ce ne sono
      for (const key in this.route.snapshot.queryParams) {
         params = params.set(key, this.route.snapshot.queryParams[key]);
      }
      return params;
   }

   ngOnDestroy() {
      if (this.mapMoveSubscription) {
         this.mapMoveSubscription.unsubscribe();
      }

      this.appComponent.cssClass = '';
   }
}
