import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StrapiService, CollectionTypeComponent, MetaService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';

@Component({
   selector: 'app-page-view',
   templateUrl: './page-view.component.html',
   styleUrls: ['./page-view.component.scss'],
})
export class PageViewComponent extends CollectionTypeComponent implements OnInit {
   environment = environment;
   constructor(activatedRoute: ActivatedRoute, router: Router, strapi: StrapiService, meta: MetaService, private sanitizer: DomSanitizer) {
      super(activatedRoute, router, strapi, meta);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
   }

   getCoverUrl(): string {
      return this.strapi.getImageUrl(this.model.cover.url);
   }
}
